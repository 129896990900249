import { createSlice } from '@reduxjs/toolkit';

import { SignatureReqModel } from 'src/@types/documents/types';

import { dispatch } from '../store';

const initialState: SignatureReqModel = {
  documentId: undefined,
  stamp: undefined,
  x: undefined,
  y: undefined,
  page: undefined,
  width: undefined,
  height: undefined,
  finalWidth: undefined,
  finalHeight: undefined,
  isSigningFinished: false,
};

const slice = createSlice({
  name: 'signatureReqModel',
  initialState,
  reducers: {
    clearState(state) {
      state.stamp = initialState.stamp;
      state.width = initialState.width;
      state.height = initialState.height;
      state.documentId = initialState.documentId;
      state.x = initialState.x;
      state.y = initialState.y;
      state.page = initialState.page;
      state.finalWidth = initialState.finalWidth;
      state.finalHeight = initialState.finalHeight;
      state.isSigningFinished = initialState.isSigningFinished;
    },

    setSignSession(state, action) {
      state.stamp = action.payload.stamp;
      state.width = action.payload.width;
      state.height = action.payload.height;
    },

    setSignCoorditates(state, action) {
      state.x = action.payload.x;
      state.y = action.payload.y;
      state.page = action.payload.page;
    },

    setFinalSignSize(state, action) {
      state.finalWidth = action.payload.finalWidth;
      state.finalHeight = action.payload.finalHeight;
    },

    setIsSigningFinished(state, action) {
      state.isSigningFinished = action.payload.isSigningFinished;
    },
  },
});

export default slice.reducer;

export function clearSignatureReqModelState() {
  dispatch(slice.actions.clearState());
}

export function setSignImage(stamp: string, width: number, height: number) {
  dispatch(slice.actions.setSignSession({ stamp, width, height }));
}

export function setSignCoordinates( x: number, y: number, page: number) {
  dispatch(slice.actions.setSignCoorditates({  x, y, page }));
}

export function setFinalSignSize(finalWidth: number, finalHeight: number) {
  dispatch(slice.actions.setFinalSignSize({ finalWidth, finalHeight }));
}

export function setIsSigningFinished(isSignDone: boolean) {
  dispatch(slice.actions.setIsSigningFinished({ isSignDone }));
}
